<template>
  <div class="light-wrapper">
    <div class="dark-wrapper">
      <form @submit.prevent="validateAndApplyFilters">
        <!-- Поле: Место -->
        <div class="form-field">
          <label class="custom-label">Активности</label>
          <Multiselect
              v-model="selectedActivities"
              :options="activities"
              :multiple="true"
              :close-on-select="false"
              select-label="Выбрать"
              deselect-label="Убрать"
              selected-label="Выбрано"
              placeholder="Любые"
          >
            <!-- Кастомизация плейсхолдера с иконкой -->
            <template #placeholder>
              <div class="custom-placeholder">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                  <path stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16.17 10.06H7.83c-1.18 0-1.59-.79-.9-1.75l4.17-5.84c.49-.7 1.31-.7 1.79 0l4.17 5.84c.7.96.29 1.75-.89 1.75Z"/>
                  <path stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.59 18H6.42c-1.58 0-2.12-1.05-1.19-2.33l3.99-5.61h5.57l3.99 5.61c.93 1.28.39 2.33-1.19 2.33ZM12 22v-4"/>
                </svg>
                <span>Любые</span>
              </div>
            </template>
          </Multiselect>

        </div>

        <!-- Поле: Вид дома (множественный выбор через vue-multiselect) -->
        <div class="form-field">
          <label class="custom-label">Тип жилья</label>
          <Multiselect
              v-model="selectedObjectTypes"
              :options="objectTypes"
              :multiple="true"
              :close-on-select="false"
              select-label="Выбрать "
              deselect-label="Убрать"
              selected-label="Выбрано"
              placeholder="Любой"
          >
          <!-- Кастомизация плейсхолдера с иконкой -->
          <template #placeholder>
            <div class="custom-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
                <path stroke="#6D6D6D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.461 21H3V8.577L8.23 4l5.231 4.577V21ZM13.461 21H20v-9.154h-6.539M8.23 21v-2.616M6.27 14.461h3.922M6.27 10.539h3.922"/>
              </svg>
              <span>Любой</span>
            </div>
          </template>

        </Multiselect>
        </div>

        <!-- Поле: Период (диапазон дат через vue3-datepicker) -->
        <!-- Поле: Период (диапазон дат через vue-flatpickr-component) -->
        <div class="form-field form-with-icon">
          <label class="custom-label">Период</label>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" class="icon">
            <path fill="#6D6D6D" fill-rule="evenodd" d="M3.63 5.938c.405-.403.953-.63 1.524-.63a1 1 0 1 1 0 2A.154.154 0 0 0 5 7.46v10.385a.154.154 0 0 0 .154.154h12.692a.154.154 0 0 0 .154-.154V7.461a.154.154 0 0 0-.154-.153H15.54a1 1 0 1 1 0-2h2.307A2.154 2.154 0 0 1 20 7.46v10.385A2.154 2.154 0 0 1 17.846 20H5.154A2.154 2.154 0 0 1 3 17.846V7.461c0-.57.227-1.119.63-1.523Z" clip-rule="evenodd"/>
            <path fill="#6D6D6D" fill-rule="evenodd" d="M3 10.923a1 1 0 0 1 1-1h15a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1ZM7.461 3a1 1 0 0 1 1 1v4.615a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1ZM15.539 3a1 1 0 0 1 1 1v4.615a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1Z" clip-rule="evenodd"/>
            <path fill="#6D6D6D" fill-rule="evenodd" d="M6.461 6.308a1 1 0 0 1 1-1h5.77a1 1 0 0 1 0 2H7.46a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
          </svg>
          <flat-pickr
              v-model="selectedDateRange"
              :config="datePickerConfig"
              placeholder="Выберите даты"
              class="flat-pickr-input"
          />      
          <!-- Сообщение об ошибке, если период не выбран -->
          <div v-if="showDateError" class="warning">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" class="icon">
              <path fill="#EA3748" d="M8 15.167A7.173 7.173 0 0 1 .833 8 7.173 7.173 0 0 1 8 .833 7.173 7.173 0 0 1 15.167 8 7.173 7.173 0 0 1 8 15.167ZM8 1.833A6.174 6.174 0 0 0 1.833 8c0 3.4 2.767 6.167 6.167 6.167S14.167 11.4 14.167 8 11.4 1.833 8 1.833Z"/>
              <path fill="#EA3748" d="M6.113 10.387a.495.495 0 0 1-.353-.147.503.503 0 0 1 0-.706L9.533 5.76a.503.503 0 0 1 .707 0 .503.503 0 0 1 0 .707L6.467 10.24a.484.484 0 0 1-.354.147Z"/>
              <path fill="#EA3748" d="M9.887 10.387a.495.495 0 0 1-.354-.147L5.76 6.467a.503.503 0 0 1 0-.707.503.503 0 0 1 .707 0l3.773 3.774a.503.503 0 0 1 0 .706c-.1.1-.227.147-.353.147Z"/>
            </svg>
            <p class="error-message">Пожалуйста, выберите период</p>
          </div>
        </div>

        <!-- Поле: Кол-во гостей -->
        <div class="form-field form-with-icon">
          <label class="custom-label">Количество гостей</label>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" class="icon">
            <path fill="#6D6D6D" fill-rule="evenodd" d="M12.13 5a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5ZM7.38 7.75a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Z" clip-rule="evenodd"/>
            <path fill="#6D6D6D" fill-rule="evenodd" d="M7.128 14.43a8.489 8.489 0 0 1 13.086 4.265A1 1 0 0 1 19.262 20H5a1 1 0 0 1-.952-1.305 8.488 8.488 0 0 1 3.08-4.266Zm5.003.368A6.489 6.489 0 0 0 6.536 18h11.19a6.49 6.49 0 0 0-5.595-3.202Z" clip-rule="evenodd"/>
          </svg>
          <input v-model="guestCount" type="number" placeholder="Кол-во гостей" @input="validateGuestCount" />
        </div>

        <button type="submit" class="btn">Поиск предложений</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css'; // Импортируем стили Flatpickr
import { Russian } from 'flatpickr/dist/l10n/ru.js';


export default {
  name: 'SearchForm',
  components: {
    Multiselect,
    flatPickr
  },
  data() {
      return {
      objectTypes: [], // Виды домов
      selectedObjectTypes: [], // Выбранные типы домов,
      activities: [], // Виды домов
      selectedActivities: [], // Выбранные типы домов
      selectedDateRange: [],
      guestCount: 1,
      showDateError: false, // Флаг для показа ошибки по выбору дат
      datePickerConfig: {
        mode: 'range',
        dateFormat: 'd.m.Y',
        locale: Russian // Здесь подключаем локаль
      }
      };
  },
  methods: {
    // Метод для загрузки активностей через API
    async loadActivities() {
      try {
        const response = await axios.get('https://лк.домшеринг.рф/api/atv?sort=nm');
        // Извлекаем только имена активностей
        this.activities = response.data.data.map(activity => activity.nm);
      } catch (error) {
        console.error('Ошибка при загрузке активностей:', error);
      }
    },
    async loadObjectTypes() {
      try {
        const response = await axios.get('https://лк.домшеринг.рф/api/obj');
        // Извлекаем только имена активностей
        this.objectTypes = response.data.data.map(object => object.nm);
      } catch (error) {
        console.error('Ошибка при загрузке типов объектов:', error);
      }
    },
    // Метод для валидации перед отправкой
    validateAndApplyFilters() {
      // Проверка, что выбранный диапазон дат не является пустым
      if (!this.selectedDateRange || this.selectedDateRange.trim() === '') {
        this.showDateError = true; // Показываем ошибку, если период не выбран
        return;
      }

      // Убираем ошибку, если период выбран
      this.showDateError = false;

      // Формируем фильтры и отправляем их
      this.applyFilters();
    },
    applyFilters() {
      const filters = {
        activities: this.selectedActivities,
        objectTypes: this.selectedObjectTypes,
        dateRange: this.selectedDateRange,
        guestCount: this.guestCount
      };
      this.$emit('apply-filters', filters);  // Передаем фильтры в родительский компонент
    },
    // Валидация количества гостей (1 - 50)
    validateGuestCount() {
      if (this.guestCount < 1) {
        this.guestCount = 1; // Минимум 1 гость
      } else if (this.guestCount > 50) {
        this.guestCount = 50; // Максимум 50 гостей
      }
    },
    search() {
      console.log('Выбранные виды домов:', this.selectedObjectTypes);
      console.log('Выбранные активности:', this.selectedActivities);
      console.log('Выбранный период:', this.selectedDateRange);
    }
  },
  mounted() {
    // Загружаем активности при монтировании компонента
    this.loadActivities();
    this.loadObjectTypes();
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>

/* Поля формы располагаются в линию */

form {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
}

@media (width < 768px) {
  form {
    flex-direction: column;
  }
}


@media (width >= 768px) and (width < 1280px) {
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.multiselect-custom {
  border: 1px solid #D5D6DE;
  background: #FFFFFF;
  font-size: 16px;
  color: #6D6D6D;
  font-weight: 400;
  font-family: inherit;
}

.multiselect-custom::before {
    top: 105% !important;
}

/* Общие стили для полей формы */
.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 48px; */
  width: 100%;
}

@media (width >= 768px) {
  .form-field {
    width: 288px;
    /* height: 56px; */
  } 
}

@media (width >= 768px) and (width < 1280px) {
  .form-field {
    width: 100%;
  }
}

@media (width >= 1280px) and (width < 1440px) {
  .form-field {
    width: 200px;
  } 
}

@media (width >= 1440px) {
  .form-field {
    width: 232px;
  } 
}

.form-field label {
  color: #fff;
  margin-bottom: 8px;
}

.form-field input,
.form-field select {
  height: 100%;
  padding: 17px 20px;
  border: 1px solid #D5D6DE;
  border-radius: 10px;
  background: #FFFFFF;
  font-size: 14px;
}

/* Кнопка поиска */
.btn {
  width: 100%;
  height: 48px;
  font-size: 14px;
}

@media (width >= 768px) {
  .btn {
    height: 54px;
    font-size: 18px;
    line-height: 20px;
  } 
}

@media (width >= 1280px) and (width < 1440px) {
  .btn {
    width: 200px;
  } 
}

@media (width >= 1440px) {
  .btn {
    width: 232px;
  } 
}

.btn:hover {
  background-color: #157a45;
}

/* Сообщение об ошибке */

.warning {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #ffffff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #ffffff;
  margin-top: -9px;
  border: 1px solid #D5D6DE;
  border-top: none;
  padding: 0 9px;
  z-index: 10;
}

@media (width >= 1280px) {
  .warning {
    padding: 0 4px;
  } 
}

@media (width >= 1440px) {
  .warning {
    padding: 0 17px;
  } 
}
.error-message {
  color: red;
  font-size: 12px;
  margin: 0;
}

.custom-placeholder {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Зазор между иконкой и текстом */
}

.custom-placeholder svg {
  width: 24px;
  height: 24px;
  color: #666;
  /* Цвет иконки */
}

.custom-placeholder span {
  font-size: 14px;
  color: #666; /* Цвет текста */
}

.form-with-icon {
  position: relative; /* Для позиционирования иконки */
}

.icon {
  position: absolute;
  left: 10px; /* Расстояние от левого края */
  top: 70%;
  transform: translateY(-50%); /* Центрируем иконку вертикально */
  pointer-events: none; /* Иконка не будет мешать вводу */
}

input {
  padding-left: 40px !important; /* Добавляем отступ, чтобы текст не перекрывал иконку */
  padding: 10px;
  border: 1px solid #D5D6DE;
  border-radius: 8px;
  font-size: 16px;
}

.custom-label {
  font-size: 16px;
  color: #fff; /* Цвет текста */
}

</style>